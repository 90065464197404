import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormTextInput, Form, Card, Button, Alert } from 'tabler-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApiError from '../Common/ApiError';
import { getError } from '../../selectors/getError';
import { isLoading } from '../../selectors/isLoading';
import { submitNewPassword } from 'src/actions/user/password-recovery';

class SubmitNewPasswordForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            password: '',
            confirm: '',
            isSubmitted: false,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    onSubmit (event) {
        event.preventDefault();

        this.props.submitNewPassword(this.props.hash, this.state.password).then(() => {
            this.setState({
                isSubmitted: true,
            });

            console.log('state after', this.state);
        });
    }

    render () {
        const { password, confirm, isSubmitted } = this.state;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>Input new password</Card.Title>

                    { isSubmitted && (
                        <Alert type='success' icon='alert-triangle'>
                            <strong>Password successfully reset. Go to <a href='/authentication'>login page</a></strong>
                        </Alert>
                    ) }

                    <ApiError error={this.props.submitError} />

                    <form onSubmit={this.onSubmit}>
                        <FormTextInput
                            autoFocus
                            name='password'
                            type='password'
                            label='Password'
                            placeholder='Enter password'
                            value={password}
                            onChange={this.onInputChange}
                        />

                        <FormTextInput
                            name='confirm'
                            type='password'
                            label='Repeat Password'
                            placeholder='Enter password'
                            value={confirm}
                            onChange={this.onInputChange}
                        />

                        <Form.Footer>
                            <Button
                                block
                                color='primary'
                                onClick={this.onSubmit}
                                disabled={isSubmitted || password === '' || password !== confirm || this.props.isSubmitting}
                                loading={this.props.isSubmitting}
                            >
                                Submit new password
                            </Button>
                        </Form.Footer>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

SubmitNewPasswordForm.propTypes = {
    submitNewPassword: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submitError: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
    hash: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    isSubmitting: isLoading(state, 'submitNewPassword'),
    submitError: getError(state, 'password-recovery', 'submit'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            submitNewPassword,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubmitNewPasswordForm);

