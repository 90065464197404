import React, { Component } from 'react';
import { Button, Card, Dimmer, Table, Text } from 'tabler-react';
import PropTypes from 'prop-types';
import { createApiClient as api } from '../../../../../services/api-client';
import { formatDateAndTime } from 'src/helpers';
import WalletClosingStatementActions from 'src/components/Content/Users/Wallets/Wallet/WalletClosingStatementActions';

class ClosingStatement extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            statements: [],
        };
    }

    componentDidMount () {
        this.fetchClosingStatements();
    }

    fetchClosingStatements () {
        this.setState({
            isLoading: true,
        });

        api()
            .get(`/admin-wallet-closing-statement/wallets/${this.props.wallet_id}/closing-statements`)
            .then((data) => {
                this.setState({
                    statements: data.data,
                    isLoading: false,
                    error: null,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data.message,
                    isLoading: false,
                });
            });
    }

    generateClosingStatement = async () => {
        this.setState({
            isLoading: true,
        });

        await api()
            .post(`/admin-wallet-closing-statement/wallets/${this.props.wallet_id}/closing-statements`)
            .then(() => {
                this.setState({
                    isLoading: false,
                });

                this.fetchClosingStatements();
            })
            .catch((data) => {
                this.setState({
                    error: data.data.message,
                    isLoading: false,
                });
            });
    }

    renderBody () {
        const { statements, isLoading } = this.state;
        const { wallet_number } = this.props;

        return (
            <Dimmer active={isLoading} loader>
                <Table cards={true} striped={true} responsive={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColHeader>Generated At</Table.ColHeader>
                            <Table.ColHeader>Actions</Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {statements.map((statement) => (
                            <Table.Row className='small' key={statement.id}>
                                <Table.Col>{formatDateAndTime(statement.created_at)}</Table.Col>
                                <Table.Col>
                                    <WalletClosingStatementActions
                                        id={statement.id}
                                        created_at={statement.created_at}
                                        wallet_number={wallet_number}
                                        update_list={() => this.fetchClosingStatements()}
                                    />
                                </Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Dimmer>
        );
    }

    renderError () {
        return (
            <Card.Alert color='danger'>
                <Text.Small>{this.state.error}</Text.Small>
            </Card.Alert>
        );
    }

    render () {
        const { error, isLoading } = this.state;

        return (
            <Card>
                <Card.Header>
                    <Card.Title>Closing statements</Card.Title>
                    {isLoading === false && (
                        <Card.Options>
                            <Button size='sm' color='primary' onClick={this.generateClosingStatement}>Generate</Button>
                        </Card.Options>
                    )}
                </Card.Header>

                {error ? this.renderError() : this.renderBody()}
            </Card>
        );
    }
}

ClosingStatement.propTypes = {
    wallet_id: PropTypes.number.isRequired,
    wallet_number: PropTypes.string.isRequired,
};

export default ClosingStatement;
