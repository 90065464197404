import { handleActions } from 'redux-actions';

import {
    SUBMIT_NEW_PASSWORD_FAILURE,
    SUBMIT_NEW_PASSWORD_REQUEST,
    USER_PASSWORD_RECOVERED,
} from 'src/actions/user/password-recovery';

export default handleActions(
    {
        [SUBMIT_NEW_PASSWORD_REQUEST]: () => false,
        [SUBMIT_NEW_PASSWORD_FAILURE]: () => false,
        [USER_PASSWORD_RECOVERED]: () => true,
    },
    false,
);
