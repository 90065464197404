import { handleActions } from 'redux-actions';

import {
    CONFIRM_PASSWORD_RECOVERY_FAILURE,
    CONFIRM_PASSWORD_RECOVERY_REQUEST,
    CONFIRM_PASSWORD_RECOVERY_SUCCESS,
} from 'src/actions/user/password-recovery';

export default handleActions(
    {
        [CONFIRM_PASSWORD_RECOVERY_REQUEST]: () => true,
        [CONFIRM_PASSWORD_RECOVERY_SUCCESS]: () => false,
        [CONFIRM_PASSWORD_RECOVERY_FAILURE]: () => false,
    },
    false,
);
