import { combineReducers } from 'redux';
import isCreating from './is-creating';
import isConfirming from './is-confirming';
import isSubmitting from './is-submitting';
import isSubmitted from 'src/reducers/password-recovery/is-submitted';

export default combineReducers({
    isCreating,
    isConfirming,
    isSubmitting,
    isSubmitted,
});
