import { handleActions } from 'redux-actions';

import {
    SUBMIT_NEW_PASSWORD_FAILURE,
    SUBMIT_NEW_PASSWORD_REQUEST,
    SUBMIT_NEW_PASSWORD_SUCCESS,
} from 'src/actions/user/password-recovery';

export default handleActions(
    {
        [SUBMIT_NEW_PASSWORD_REQUEST]: () => true,
        [SUBMIT_NEW_PASSWORD_SUCCESS]: () => false,
        [SUBMIT_NEW_PASSWORD_FAILURE]: () => false,
    },
    false,
);
