import { createAction } from 'redux-actions';

export const CREATE_PASSWORD_RECOVERY_REQUEST = 'user/CREATE_PASSWORD_RECOVERY_REQUEST';
export const CREATE_PASSWORD_RECOVERY_SUCCESS = 'user/CREATE_PASSWORD_RECOVERY_SUCCESS';
export const CREATE_PASSWORD_RECOVERY_FAILURE = 'user/CREATE_PASSWORD_RECOVERY_FAILURE';

export const CONFIRM_PASSWORD_RECOVERY_REQUEST = 'user/CONFIRM_PASSWORD_RECOVERY_REQUEST';
export const CONFIRM_PASSWORD_RECOVERY_SUCCESS = 'user/CONFIRM_PASSWORD_RECOVERY_SUCCESS';
export const CONFIRM_PASSWORD_RECOVERY_FAILURE = 'user/CONFIRM_PASSWORD_RECOVERY_FAILURE';

export const SUBMIT_NEW_PASSWORD_REQUEST = 'user/SUBMIT_NEW_PASSWORD_REQUEST';
export const SUBMIT_NEW_PASSWORD_SUCCESS = 'user/SUBMIT_NEW_PASSWORD_SUCCESS';
export const SUBMIT_NEW_PASSWORD_FAILURE = 'user/SUBMIT_NEW_PASSWORD_FAILURE';

export const USER_PASSWORD_RECOVERED = 'user/PASSWORD_RECOVERED';

const createPasswordRecoveryRequest = createAction(CREATE_PASSWORD_RECOVERY_REQUEST);
const createPasswordRecoverySuccess = createAction(CREATE_PASSWORD_RECOVERY_SUCCESS);
const createPasswordRecoveryFailure = createAction(CREATE_PASSWORD_RECOVERY_FAILURE);

const confirmPasswordRecoveryRequest = createAction(CONFIRM_PASSWORD_RECOVERY_REQUEST);
const confirmPasswordRecoverySuccess = createAction(CONFIRM_PASSWORD_RECOVERY_SUCCESS);
const confirmPasswordRecoveryFailure = createAction(CONFIRM_PASSWORD_RECOVERY_FAILURE);

const submitNewPasswordRequest = createAction(SUBMIT_NEW_PASSWORD_REQUEST);
const submitNewPasswordSuccess = createAction(SUBMIT_NEW_PASSWORD_SUCCESS);
const submitNewPasswordFailure = createAction(SUBMIT_NEW_PASSWORD_FAILURE);

export const userPasswordRecovered = createAction(USER_PASSWORD_RECOVERED);

export const createPasswordRecovery = (email) => async (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(createPasswordRecoveryRequest());
            const result = await api.post('/admin-user/password-recoveries', { email });

            dispatch(createPasswordRecoverySuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(createPasswordRecoveryFailure(response.data));
        }
    });

export const confirmPasswordRecovery = (email, code, hash) => async (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(confirmPasswordRecoveryRequest());
            const result = await api.put(`/admin-user/password-recoveries/${hash}/confirm`, { email, code });

            dispatch(confirmPasswordRecoverySuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(confirmPasswordRecoveryFailure(response.data));
        }
    });

export const submitNewPassword = (hash, password) => async (dispatch, getState, { api }) => {
    try {
        dispatch(submitNewPasswordRequest());

        const result = await api.put(`/admin-user/password-recoveries/${hash}/submit-password`, { password });

        dispatch(submitNewPasswordSuccess(result.data));
        dispatch(userPasswordRecovered(result.data));
    } catch (response) {
        dispatch(submitNewPasswordFailure(response.data));
    }
};
