import React, { useMemo, useState } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { FILTER_MASKS } from 'src/constants';
import PaginatedFilteredList from 'src/components/Common/PaginatedFilteredList';
import moment from 'moment-timezone';
import { formatDateAndTime } from 'src/helpers';

const uri = '/admin-reporting/wallet-balance';
const exportConfig = { uri: '/admin-reporting/wallet-balance/download', filename: 'wallet-balance.csv' };

const WalletBalance = () => {
    const [items, setItems] = useState([]);

    const updateItems = (items) => {
        setItems(items);
    };

    const renderList = () => (
        <PaginatedFilteredList
            uri={uri}
            columns={columns}
            filterFields={filterFields}
            updateItems={updateItems}
            items={items}
            exportConfig={exportConfig}
            sortBy={'created_at'}
        />
    );

    const filterFields = useMemo(
        () => [
            {
                title: 'On Date',
                name: 'date',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: moment().format('YYYY-MM-DD'),
            },
            {
                title: 'Wallet Number',
                name: 'wallet_number',
                type: 'text',
                placeholder: FILTER_MASKS.walletNumber.placeholder,
                mask: FILTER_MASKS.walletNumber.mask,
            },
        ],
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
                sortable: false,
            },
            {
                Header: 'Wallet Number',
                accessor: 'number',
                sortable: false,
            },
            {
                Header: 'Wallet Creation Date',
                accessor: 'created_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Wallet Closing Date',
                accessor: 'deactivated_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Wallet Holder',
                accessor: 'holder_name',
                sortable: false,
            },
            {
                Header: 'Balance Amount',
                accessor: 'balance_amount',
                sortable: false,
            },
            {
                Header: 'Reserve Amount',
                accessor: 'reserve_amount',
                sortable: false,
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                sortable: false,
            },
        ],
        [],
    );

    return  <SiteWrapper title='Wallet Balance'>{renderList()}</SiteWrapper>;
};

export default WalletBalance;
