import React, { useEffect, useState } from 'react';
import { Card, Dimmer, Table, Text } from 'tabler-react';
import PropTypes from 'prop-types';
import { formatDateAndTime, walletStatus } from 'src/helpers';
import { createApiClient as api } from '../../../../../services/api-client';
import { WALLET_TYPES } from 'src/constants';

const MainData = ({ wallet_id, status }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [wallet, setWallet] = useState({
        id: null,
        number: null,
        is_active: null,
        iban: null,
        created_at: null,
        deactivated_at: null,
    });

    useEffect(() => {
        api()
            .get(`/admin-wallet/wallets/${wallet_id}`)
            .then((data) => {
                setWallet(data.data);
                setIsLoading(false);
            })
            .catch((data) => {
                setError(data.data.message);
                setIsLoading(false);
            });
    }, [status, wallet_id]);

    const renderWalletType = (type) => {
        if (WALLET_TYPES[type]) {
            return WALLET_TYPES[type];
        }

        return type;
    };

    const renderBody = () => (
        <>
            <Dimmer active={isLoading} loader>
                <Table className='small' striped={true} cards={true}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Col>Wallet number</Table.Col>
                            <Table.Col>{wallet.number}</Table.Col>
                        </Table.Row>

                        <Table.Row>
                            <Table.Col>IBAN</Table.Col>
                            <Table.Col>{wallet.iban}</Table.Col>
                        </Table.Row>

                        <Table.Row>
                            <Table.Col>Provider</Table.Col>
                            <Table.Col>{renderWalletType(wallet.type)}</Table.Col>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Dimmer>
            {isLoading === false && (
                <Card.Footer>
                    <Text.Small>
                        Created at {formatDateAndTime(wallet.created_at)}
                        {wallet.deactivated_at !== null &&
                            <div>Deactivated at {formatDateAndTime(wallet.deactivated_at)}</div>}
                    </Text.Small>
                </Card.Footer>
            )}
        </>
    );

    const renderError = () => (
        <Card.Alert color='danger'>
            <Text.Small>{this.state.error}</Text.Small>
        </Card.Alert>
    );

    return (
        <Card>
            <Card.Header>
                <Card.Title>Main wallet data</Card.Title>
                {isLoading === false && <Card.Options>{walletStatus(wallet.is_active)}</Card.Options>}
            </Card.Header>

            {error ? renderError() : renderBody()}
        </Card>
    );
};

MainData.propTypes = {
    wallet_id: PropTypes.number.isRequired,
    status: PropTypes.bool.isRequired,
};

export default MainData;
