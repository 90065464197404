import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createPasswordRecovery, confirmPasswordRecovery, submitNewPassword } from '../actions/user/password-recovery';
import PropTypes from 'prop-types';
import TwoFAForm from './AuthenticationPage/TwoFAForm';
import { getError } from '../selectors/getError';
import { StandaloneFormPage } from 'tabler-react';
import logo from '../images/logo.svg';
import CreatePasswordRecoveryForm from 'src/components/PasswordRecoveryPage/CreatePasswordRecoveryForm';
import ConfirmPasswordRecoveryForm from 'src/components/PasswordRecoveryPage/ConfirmPasswordRecoveryForm';
import SubmitNewPasswordForm from 'src/components/PasswordRecoveryPage/SubmitNewPasswordForm';

export class PasswordRecoveryPage extends Component {
    constructor (props) {
        super(props);

        this.state = {
            step: 'create-password-recovery',
            params: {
                email: '',
                hash: '',
            },
        };

        this.createPasswordRecovery = this.createPasswordRecovery.bind(this);
        this.confirmPasswordRecovery = this.confirmPasswordRecovery.bind(this);
        this.submitNewPassword = this.submitNewPassword.bind(this);

        this.changeStep = this.changeStep.bind(this);
    }

    createPasswordRecovery (email) {
        this.props.createPasswordRecovery(email);
    }

    confirmPasswordRecovery (email, code, hash) {
        this.props.confirmPasswordRecovery(email, code, hash);
    }

    submitNewPassword (hash, password) {
        this.props.submitNewPassword(hash, password);
    }

    changeStep (step, params) {
        this.setState({
            step,
            params,
        });
    }

    renderPasswordRecoveryStep () {
        if (this.state.step === 'create-password-recovery') {
            return <CreatePasswordRecoveryForm changeStep={this.changeStep} />;
        } else if (this.state.step === 'confirm-password-recovery') {
            return <ConfirmPasswordRecoveryForm
                changeStep={this.changeStep}
                hash={this.state.params.hash}
                email={this.state.params.email}
            />;
        } else if (this.state.step === 'submit-new-password') {
            return <SubmitNewPasswordForm changeStep={this.changeStep} hash={this.state.params.hash} />;
        }
    }

    render () {
        return (
            <div className='authentication-page'>
                <StandaloneFormPage imageURL={logo}>{this.renderPasswordRecoveryStep()}</StandaloneFormPage>
            </div>
        );
    }
}

PasswordRecoveryPage.propTypes = {
    createPasswordRecovery: PropTypes.func.isRequired,
    confirmPasswordRecovery: PropTypes.func.isRequired,
    submitNewPassword: PropTypes.func.isRequired,
    isCreating: PropTypes.bool.isRequired,
    isConfirming: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    createError: PropTypes.object,
    confirmError: PropTypes.object,
    submitError: PropTypes.object,
};

const mapStateToProps = (state) => ({
    isCreating: state.passwordRecovery.isCreating,
    isConfirming: state.passwordRecovery.isConfirming,
    isSubmitting: state.passwordRecovery.isSubmitting,
    createError: getError(state, 'password-recovery', 'create'),
    confirmError: getError(state, 'password-recovery', 'confirm'),
    submitError: getError(state, 'password-recovery', 'submit'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createPasswordRecovery,
            confirmPasswordRecovery,
            submitNewPassword,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryPage);
