import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormTextInput, Form, Card, Button } from 'tabler-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApiError from '../Common/ApiError';
import { getError } from '../../selectors/getError';
import { isLoading } from '../../selectors/isLoading';
import { createPasswordRecovery } from 'src/actions/user/password-recovery';

class CreatePasswordRecoveryForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            username: '',
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    onSubmit (event) {
        event.preventDefault();

        this.props.createPasswordRecovery(this.state.username).then((response) => {
            this.props.changeStep('confirm-password-recovery', { email: this.state.username, hash: response.hash });
        });
    }

    render () {
        const { username } = this.state;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>Input your Email address</Card.Title>

                    <ApiError error={this.props.createError} />

                    <form onSubmit={this.onSubmit}>
                        <FormTextInput
                            autoFocus
                            name='username'
                            label='Email Address'
                            placeholder='Enter your email address'
                            value={username}
                            onChange={this.onInputChange}
                        />

                        <Form.Footer>
                            <Button
                                block
                                color='primary'
                                onClick={this.onSubmit}
                                disabled={username === '' || this.props.isCreating}
                                loading={this.props.isCreating}
                            >
                                Send confirmation code
                            </Button>
                        </Form.Footer>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

CreatePasswordRecoveryForm.propTypes = {
    createPasswordRecovery: PropTypes.func.isRequired,
    isCreating: PropTypes.bool.isRequired,
    createError: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isCreating: isLoading(state, 'createPasswordRecovery'),
    createError: getError(state, 'password-recovery', 'create'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createPasswordRecovery,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordRecoveryForm);
