import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormTextInput, Form, Card, Button } from 'tabler-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApiError from '../Common/ApiError';
import { getError } from '../../selectors/getError';
import { isLoading } from '../../selectors/isLoading';
import { confirmPasswordRecovery } from 'src/actions/user/password-recovery';

class ConfirmPasswordRecoveryForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            code: '',
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    onSubmit (event) {
        event.preventDefault();

        this.props.confirmPasswordRecovery(this.props.email, this.state.code, this.props.hash).then((response) => {
            this.props.changeStep('submit-new-password', { email: this.props.email, hash: this.props.hash });
        });
    }

    render () {
        const { code } = this.state;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>Input the code received by Email</Card.Title>

                    <ApiError error={this.props.confirmError} />

                    <form onSubmit={this.onSubmit}>
                        <FormTextInput
                            autoFocus
                            name='code'
                            label='Code'
                            placeholder='Enter code'
                            value={code}
                            onChange={this.onInputChange}
                        />

                        <Form.Footer>
                            <Button
                                block
                                color='primary'
                                onClick={this.onSubmit}
                                disabled={code === '' || this.props.hash === undefined || this.props.isConfirming}
                                loading={this.props.isConfirming}
                            >
                                Confirm
                            </Button>
                        </Form.Footer>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

ConfirmPasswordRecoveryForm.propTypes = {
    confirmPasswordRecovery: PropTypes.func.isRequired,
    isConfirming: PropTypes.bool.isRequired,
    confirmError: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
    hash: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    isConfirming: isLoading(state, 'confirmPasswordRecovery'),
    confirmError: getError(state, 'password-recovery', 'confirm'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            confirmPasswordRecovery,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPasswordRecoveryForm);
