import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createApiClient } from '../../../../../../services/api-client';
import { toast } from 'react-toastify';
import { Form, Table } from 'tabler-react';
import cx from 'classnames';

const EditableRow = ({ user, name, inputType, title, formatter = (value) => value }) => {
    const [defaultValue, setDefaultValue] = useState(user[name]);
    const [value, setValue] = useState(defaultValue);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const submitButtonRef = useRef();
    const isMouseOverRef = useRef(false);

    const api = useMemo(() => createApiClient(), []);

    const handleBlur = useCallback(
        (event) => {
            if (isMouseOverRef.current) {
                return event.preventDefault();
            }

            setValue(defaultValue);
            setIsEdit(false);
        },
        [defaultValue, setIsEdit],
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            const formattedValue = formatter(value);

            if (defaultValue === formattedValue) {
                setIsEdit(false);
                return;
            }

            setLoading(true);

            return api
                .put(`admin-user/users/${user.id}`, { [name]: formattedValue })
                .then(({ data }) => {
                    setDefaultValue(data[name]);
                    toast.success('User updated successfully!');
                })
                .catch((error) => {
                    toast.error(error.message || error.data?.message);
                })
                .finally(() => {
                    setLoading(false);
                    setIsEdit(false);
                });
        },
        [api, defaultValue, formatter, name, user.id, value],
    );

    const renderActions = () => {
        if (!user.is_active) {
            return null;
        }

        return (
            <Table.Col>
                {isEdit ? (
                    <button
                        onMouseOver={() => (isMouseOverRef.current = true)}
                        onMouseOut={() => (isMouseOverRef.current = false)}
                        className={cx('btn btn-sm btn-block', {
                            'btn-loading': loading,
                        })}
                        onClick={() => submitButtonRef.current.click()}
                    >
                        Save
                    </button>
                ) : (
                    <button className='btn btn-sm btn-block' onClick={() => setIsEdit(true)}>
                        Change
                    </button>
                )}
            </Table.Col>
        );
    };

    return (
        <Table.Row>
            <Table.Col>{title}</Table.Col>
            <Table.Col>
                {isEdit ? (
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            required
                            autoFocus
                            name={name}
                            value={value}
                            placeholder={title}
                            onChange={(event) => setValue(event.target.value)}
                            onBlur={handleBlur}
                            type={inputType}
                        />
                        <input type='submit' hidden ref={submitButtonRef} />
                    </Form>
                ) : (
                    defaultValue
                )}
            </Table.Col>
            {renderActions()}
        </Table.Row>
    );
};

export default EditableRow;
